import * as React from "react"

import Home from "../../route/home"
 


 

// markup
const IndexPage = (props) => {
  return (
    <React.Fragment>
      <Home props={props} />
      </React.Fragment>
  )
}

export default IndexPage
